import React from "react"
import styled from 'styled-components'
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import DefaultButton from "../components/button/DefaultButton"

const Image = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "image-404.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return <Img fluid={data.placeholderImage.childImageSharp.fluid} />
}

const Wrapper = styled.div`
	padding: 200px 100px;
	min-height: 100vh;
	text-align: center;
`
const Content = styled.div`
	display: grid;
	align-items: center;
	grid-template-columns: repeat(2, 1fr);
	grid-column-gap: 70px;
	@media(max-width: 768px) {
		grid-template-columns: repeat(1, 1fr);
		grid-row-gap: 50px;
	}
`
const Text = styled.div`
	text-align: left !important;
	& h1 {
		font-size: 38px;
		line-height: 1.2;
	}
	& p {
		margin-top: 15px;
	}
	& .btn {
		margin-top: 20px;
		width: 150px;
	}
`

const NotFoundPage = ({ location }) => (
  <Layout location={location}>
    <SEO title="404: Not found" />
    <Wrapper>
    	<div className="container">
    		<Content>
    			<div><Image /></div>
    			<Text>
		    		<h1>Uh Oh! <br/> Page Not Found.</h1>
		    		<p>Maybe You Landed On The Wrong Page. Let's Take You To Premier Metal Structures Homepage</p>
		    		<Link to="/">
				    	<DefaultButton text="Home" />
				    </Link>
		    	</Text>
    		</Content>
    	</div>
    </Wrapper>
    
  </Layout>
)

export default NotFoundPage
